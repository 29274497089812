import logo from 'images/communities/Baltimore/logo.svg'
import dashboardHeaderImage from 'images/communities/Baltimore/banner.jpg'
import mapHeaderImage from 'images/communities/Baltimore/landing.jpg'
import loginBackgroundImg from 'images/communities/Baltimore/background-image.jpg'
import favicon from 'images/communities/Baltimore/favicon.png'
import homeLogo from 'images/home/community-logos/baltimore.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'baltimore',
  name: 'Baltimore',
  position: 11,
  partnerSupportEmail: 'support@artseveryday.org',
  schoolSupportEmail: 'support@artseveryday.org',
  stylesheet: 'baltimore.scss',
  mapRedirectPath: '/partner-portal',
  organizationUrl: 'https://artseveryday.org/',
  faqUrl:
    'https://drive.google.com/file/d/1Fwxkr3MKMx3Id747FQwVObMJl5Vhi_dZ/view?usp=share_link',
  mapEnabled: true,
  schoolPortalEnabled: false,
  partnerPortalEnabled: false,
  partnerProgramsEnabled: false,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 79,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: [
      'networks',
      'creativeSchoolsCategory',
      'gradesServed',
      'programDisciplines',
      'programTypes',
      'outcomeTypes',
      'financialAssistanceTypes',
    ],
  },
  schoolSearchFilters: [
    'coursesOffered',
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 39.296987, lng: -76.615586 },
    radius: 40, //in miles
  },
  directoryFilters: {
    blockList: [],
  },
  forumFilters: {
    blockList: [],
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'bal-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: 'navigate to Arts Every Day homepage',
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Arts Every Day - Baltimore',
  },
  communityLogo: {
    src: logo,
    alt: 'Arts Every Day - Inspire Students, Enhance Learning',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title:
      'artlook Map: Increasing Arts Education in Baltimore City Public Schools',
    searchHeader: 'Find Schools & Partners in Baltimore',
    welcomeSearchHeader: 'Explore Arts Education in Baltimore',
    welcomeHeader: `artlook<sup>®</sup> Baltimore`,
    welcomeMessage: `
      <p>
        <em>artlook<sup>®</sup> Baltimore</em> is your one-stop-shop for information on arts education in Baltimore City. Developed in partnership with arts and cultural organizations and teaching artists throughout the State of Maryland, <em>artlook<sup>®</sup> Baltimore</em> illustrates the relationships between these opportunities and programs and Baltimore City Schools.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> and help us expand equity and access to quality arts education for every child in Baltimore City Public Schools.
      </p>
    `,
  },
  tagline:
    'The arts, for every student, in every Baltimore City Public School, every day.',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>Welcome to <em>artlook<sup>®</sup> Baltimore!</em></h3>
        <p>
          <em>artlook<sup>®</sup> Baltimore</em> is the only platform of its kind that combines data from Baltimore City public schools and arts program providers (like you!). Did you ever wonder what schools need your resources the most? By taking the time to complete your profile, you are joining a collaborative community effort to reach every school in Baltimore City.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's school partnership information.</strong>
          If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Baltimore</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Baltimore</em> is the only platform of its kind that combines data from Baltimore-area public schools (like you!) and arts partners. The goal is to collect arts access data and use it to make the case for more funding, better staffing, and improved policies. But we can't do it without you!
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about YOUR school's arts education offerings and partnerships.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} artlook Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.</p>
    <p>If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>`,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
