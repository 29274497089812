import logo from 'images/communities/Alabama/artlook-alabama-logo.svg'
import secondaryLogo from 'images/communities/Alabama/logo.png'
import thirdLogo from 'images/communities/Alabama/secondary-community-logo.gif'
import dashboardHeaderImage from 'images/communities/Alabama/banner.jpg'
import mapHeaderImage from 'images/communities/Alabama/landing.jpg'
import loginBackgroundImg from 'images/communities/Alabama/background-image.jpg'
import favicon from 'images/communities/Alabama/favicon.png'
import homeLogo from 'images/home/community-logos/alabama.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'alabama',
  name: 'Alabama',
  position: 8,
  partnerSupportEmail: 'artlookal@alartsalliance.org',
  schoolSupportEmail: 'ArtlookAL@alartsalliance.org',
  stylesheet: 'alabama.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.alabamaachieves.org/',
  secondaryOrganizationUrl: 'https://www.alartsalliance.org/artlookalabama',
  faqUrl:
    'https://drive.google.com/file/d/1oFq8Wf_Y5uRbCDP0gDKUGmzROm-dIE4Y/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 44,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 32.360543, lng: -86.255659 },
    radius: 250, //in miles
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'alabama-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Alabama's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Alabama State Department of Education',
  },
  secondaryFooterLogo: {
    src: secondaryLogo,
    alt: 'Alabama Arts Alliance',
    link: config.organizationUrl,
  },
  thirdSideBySideLogo: {
    src: thirdLogo,
    alt: 'Alabama Arts Alliance',
    link: config.secondaryOrganizationUrl,
  },
  firstSubLogo: {
    src: secondaryLogo,
    alt: 'Alabama Arts Alliance',
    link: config.organizationUrl,
  },
  secondSubLogo: {
    src: thirdLogo,
    alt: 'Alabama Arts Alliance',
    link: config.secondaryOrganizationUrl,
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in Alabama Public Schools',
    searchHeader: 'Find Schools & Partners in Alabama',
    welcomeSearchHeader: 'Explore Arts Education in Alabama',
    welcomeHeader: `artlook<sup>®</sup> Alabama`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> is your one-stop-shop for information on arts education in our state. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in Alabama Public Schools.
      </p>
      <p>
        Developed in close coordination with Alabama's arts educators, this platform can show you everything from which art teachers and arts partners are in which schools to what arts programming, coursework, and minutes of instruction is happening where.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> today, and help us expand arts education access, quality, and equity for every child, in every grade, in every school.
      </p>
    `,
  },
  tagline:
    'Bridging the gap: Providing opportunities for better access, equity and quality arts education for Every Child, Every Chance, Every Day',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Alabama</em> is YOUR gallery for arts education data</h3>
        <p>
          artlook<sup>®</sup> Alabama is the only platform of its kind that combines data from Alabama public schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in Alabama.
        </p>
        <p>
          To get started, follow the links in the boxes below to share your organization's program and school partnership information.
        </p>
        <p>
          If you have any questions or need assistance, please contact the Alabama Arts Alliance at <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener">${config.partnerSupportEmail}</a>.
        </p>
        <p>
          <em>Arts partners (organizations) must comply with all policies and practices required by LEAs and schools regarding outside visitors on campus. Arts organizations are responsible for all individual artists they employ who work in schools. A background check is required for individual artists who intend to register as an individual arts partner. Individual arts partners will receive an email with a link to complete the process. Profile pages will not be approved until the results are verified.</em>
        </p>
        <p>
          <em>If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener">${config.partnerSupportEmail}</a></em>
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Alabama</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Alabama</em> is the only platform of its kind that combines data from Alabama public schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in Alabama.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact the Alabama Arts Alliance at <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Alabama State Department of Education Creative Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.</p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>`,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    districtSpending: {
      question: `How much funding did your school dedicate to arts education supplies, materials, and programs in {{surveySchoolYearNumber}}? Please only include funding from school and district sources, but do not include teacher salaries or capital expenses.`,
      description: `Only include funding from school and district sources, but do not include teacher salaries or capital expenses.`,
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: false,
      },
    },
    partnershipQuestion: {
      additionalTipsModal: `
        <p>
          Some arts partners may have already reported their partnerships with us, and those will appear here.
        </p>
        <p>
          If you cannot find a partner from our search box, new partnerships can be created by arts liaisons.
        </p>
        <p>
          Please give us as much contact information as possible for teaching artists so that we can locate them and reach out to get them added to artlook.
        </p>
        <p>
          The primary focus of this section is to track external arts partnerships. If a teacher at your school or the school itself is providing additional arts programming before or after school, please select 'School-Provided Arts Programming' as the name of the partner and choose Out-of-School Time (OST) as the program type. You may select more than one program type, but you may only enter 'School-Provided Arts Programming' once.
        </p>
      `,
    },
    courses: {
      coursesNote: `
        <p>
          <strong>Note on course names and numbers:</strong> If the art course is not in PowerSchool and hence does not have an "official" name and number, please use the following naming convention for course names AND numbers (use the same name for both)-:“[Discipline] – Grade [X].” 
        </p>
        <p>
          For example: “Music – Grade 2” or “Visual Arts – KG" when adding courses."
        </p>
        <p>
          <strong>Note on courses:</strong> An arts course meets the following criteria: (1) it is offered to students during the school day, (2) it is categorized as an arts course rather than another content area (e.g., math, science), and (3) it is taught by a credited arts instructor.
        </p>
      `,
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
