import React from 'react'
import PropTypes from 'prop-types'
import { CommunityImage } from 'components'
import artlook from 'images/artlook.png'
import * as Types from 'types'
import anyGivenChildLogo from 'images/communities/shared/any-given-child.png'
import { get } from 'lodash'

const propTypes = {
  community: PropTypes.object.isRequired,
  district: Types.district,
}

const defaultProps = {
  district: null,
}

function PortalNavAdditionalLogos({ community, district }) {
  const communityLogo = community.defaultBranding.communityLogo
  const secondaryFooterLogo = community.defaultBranding.secondaryFooterLogo
  const thirdSideBySideLogo = community.defaultBranding.thirdSideBySideLogo
  const districtLogo = get(district, 'logo')
  const districtIsAnyGivenChild = get(district, 'isAnyGivenChild')
  const sponsorLogo =
    get(district, 'sponsorLogo') || community.defaultBranding.sponsorLogo
  const isAlamaba = community.subdomain === 'alabama'
  return (
    <div className="additional-logos">
      {isAlamaba ? (
        <div>
          <CommunityImage image={communityLogo} className="additional-logo" />
          <CommunityImage
            image={thirdSideBySideLogo}
            className="additional-logo"
          />
        </div>
      ) : (
        <div>
          {secondaryFooterLogo && (
            <CommunityImage
              image={secondaryFooterLogo}
              className="additional-logo"
            />
          )}
          {districtLogo && (
            <CommunityImage image={districtLogo} className="additional-logo" />
          )}
          {sponsorLogo && (
            <CommunityImage image={sponsorLogo} className="additional-logo" />
          )}
          {districtIsAnyGivenChild && (
            <div className="inner">
              <div className="image-block">
                <CommunityImage
                  image={{
                    src: anyGivenChildLogo,
                    alt: "Supported by the Kennedy Center's Ensuring the Arts for Any Given Child program",
                  }}
                  className="additional-logo"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="powered-by">
        <div className="powered-by-inner">
          <p>Powered by</p>
          <img src={artlook} alt="Artlook" />
        </div>
      </div>
    </div>
  )
}

PortalNavAdditionalLogos.propTypes = propTypes
PortalNavAdditionalLogos.defaultProps = defaultProps

export default React.memo(PortalNavAdditionalLogos)
